import {createTheme, ThemeOptions} from '@mui/material/styles';

import components from './components';
import shape from './shape';
import typography from './typography';

const theme: ThemeOptions = createTheme();

const overrides = {
  components: {...theme.components, ...components},
  shape: {...theme.shape, ...shape},
  typography: {...typography(theme)}, // {...theme.typography, ...typography}, removed the theme typography as it was causing issues with the font family
};

export default overrides;
