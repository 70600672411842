// const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

const typography = {
  fontWeightBold: 700,
  fontWeightLight: 300,
  fontWeightMedium: 600,
  fontWeightRegular: 400,
};

export default typography;
