import React from 'react';

import type {SxProps} from '@mui/material';
import Box from '@mui/material/Box';

const headerHeightDefault = Number(process.env.NEXT_PUBLIC_FRONTEND_HEADER_HEIGHT);

// Styles
const styleWrapper = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};
const styleHeader = (headerHeight: number) => ({
  height: `${headerHeight}px`,
  alignItems: 'center',
  paddingRight: '40px',
  display: 'inline-flex',
  width: '100%',
});
const styleFooter = {
  minHeight: '90px',
  flexShrink: 0,
};
const styleBodyContainer = {
  display: 'flex',
  flex: '1 0 auto',
};
const styleBody = (headerHeight: number, footerHeight: number) => ({
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,

  '@media print': {
    minHeight: '100vh',
  },
});
const styleChildren = (headerHeight: number, footerHeight: number) => ({
  minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,

  '@media print': {
    minHeight: '100vh',
  },
});

export interface FrontendLayoutProps {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  footerHeight?: number;
  header?: React.ReactNode;
  headerHeight?: number;
  sxChildren?: SxProps;
  sxFooter?: SxProps;
  sxHeader?: SxProps;
}

const FrontendLayout = ({
  children = null,
  footer = null,
  footerHeight = 318,
  header = null,
  headerHeight = headerHeightDefault,
  sxChildren = {},
  sxFooter = {},
  sxHeader = {},
  ...rest
}: FrontendLayoutProps): JSX.Element => (
  <Box sx={styleWrapper} {...rest}>
    <Box style={styleBodyContainer}>
      <Box sx={styleBody(headerHeight, footerHeight)}>
        <Box p={1} sx={{...styleHeader(headerHeight), ...sxHeader}}>
          {header}
        </Box>
        <Box p={1} sx={{...styleChildren(headerHeight, footerHeight), ...sxChildren}}>
          {children}
        </Box>
      </Box>
    </Box>
    {footer && <Box sx={{...styleFooter, ...sxFooter}}>{footer}</Box>}
  </Box>
);

FrontendLayout.displayName = 'FrontendLayout';
export default FrontendLayout;
