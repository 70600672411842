import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import {event} from 'nextjs-google-analytics';

import {Box} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {ThemeOptions, useTheme} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Divider} from '@/components/Divider';
import handleEventWithConversion from '@/helpers/GoogleAnalytics';
import {RouteInterface} from '@/routes';

const MAX_WIDTH = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

const styleWrapper = (theme: ThemeOptions) => ({
  backgroundColor: 'background.footer',
  width: '100%',
  borderRadius: '50px 50px 0 0 ',
  zIndex: 3,

  // @ts-ignore
  [theme.breakpoints.down('md')]: {
    borderRadius: '20px 20px 0 0 ',
  },
});

const styleContent = (maxWidth: number) => ({
  maxWidth: `${maxWidth}px`,
  width: '100%',
});

const styleLink = {
  textDecoration: 'none',
};

const styleLogos = {
  flex: 'auto !important',
  flexWrap: 'nowrap',
};

const styleLogo = (maxWidth: number) => ({
  flex: `0 1 ${maxWidth}px`,
  '& img': {
    width: '100%',
    height: 'auto',
  },
});

const styleAddressWrapper = {
  '@media print': {
    paddingBottom: 4,
  },
};

const styleDividerWrapper = {
  '@media print': {
    display: 'none',
  },
};

const styleSocialIconsWrapper = {
  '@media print': {
    display: 'none',
  },
};

export interface FooterProps {
  maxWidth?: number;
  routes?: Array<RouteInterface>;
}

const Footer = ({maxWidth = MAX_WIDTH, routes = []}: FooterProps) => {
  const theme = useTheme();

  return (
    <Box component="footer" sx={styleWrapper(theme)}>
      <Toolbar sx={{maxWidth: `${MAX_WIDTH}px`, mx: 'auto', width: '100%'}}>
        <Grid sx={styleContent(maxWidth)}>
          <Grid container xs={12} spacing={2} justifyContent="space-between" alignItems="end" sx={styleAddressWrapper}>
            <Grid xs={12} md={6} mt={7}>
              <Typography variant="h3">{process.env.NEXT_PUBLIC_COMPANY_NAME}</Typography>
              <a target="_top" rel="noopener noreferrer" href={`${process.env.NEXT_PUBLIC_COMPANY_ADDRESS_GOOGLE_MAP_LINK}`} style={styleLink}>
                <Typography variant="h4" color="text.primary">
                  {process.env.NEXT_PUBLIC_COMPANY_ADDRESS_STREET_LINE_1}
                </Typography>
                <Typography variant="h4" color="text.primary">
                  {process.env.NEXT_PUBLIC_COMPANY_ADDRESS_STREET_LINE_2}
                </Typography>
                <Typography variant="h4" color="text.primary">
                  {process.env.NEXT_PUBLIC_COMPANY_ADDRESS_STREET_CITY} {process.env.NEXT_PUBLIC_COMPANY_ADDRESS_STREET_POSTCODE}
                </Typography>
              </a>
              <a
                onClick={() => event('Clicked to Call Main number', {category: 'Contact'})}
                target="_top"
                rel="noopener noreferrer"
                href={`tel:${process.env.NEXT_PUBLIC_COMPANY_PHONE}`}
                style={styleLink}>
                <Typography variant="h4" color="text.primary">
                  {process.env.NEXT_PUBLIC_COMPANY_PHONE}
                </Typography>
              </a>
              <a
                onClick={() => handleEventWithConversion('Clicked to 0800 U LEASE', 'Contact')}
                target="_top"
                rel="noopener noreferrer"
                href="tel:0800ULEASE"
                style={styleLink}>
                <Typography variant="h4" color="text.primary">
                  0800 ULEASE
                </Typography>
              </a>
            </Grid>
            <Grid container xs={12} md={6} gap={3} mt={{xs: 3, md: 0}} justifyContent={{xs: 'center', md: 'end'}} alignItems="center" sx={styleLogos}>
              <Grid sx={styleLogo(74)}>
                <Link href="https://www.toitu.co.nz/" style={styleLink}>
                  <Image src="/logos/logo-toitu.png" height="117" width="74" alt="TOITŪ logo" />
                </Link>
              </Grid>
              <Grid sx={styleLogo(229)}>
                <Link href="https://www.nzgbc.org.nz/" style={styleLink}>
                  <Image src="/logos/logo-nzbgc.png" height="90" width="229" alt="NZBGC logo" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} mt={4} sx={styleDividerWrapper}>
            <Divider />
          </Grid>
          <Grid container xs={12} spacing={2} my={1} justifyContent={{xs: 'center', md: 'space-between'}} alignItems="start">
            <Grid order={{xs: 4, md: 2}}>
              <Typography variant="caption" display="block">
                Copyright © {new Date().getFullYear()} {process.env.NEXT_PUBLIC_COMPANY_NAME}. All rights reserved.
              </Typography>
              <Typography variant="caption" display="block" mb={2}>
                Disclaimer: All areas and rentals quoted are approximate and subject to final measure and confirmation by {process.env.NEXT_PUBLIC_APP_NAME}{' '}
                management.
              </Typography>
            </Grid>
            <Grid xs={12} md={0} order={{xs: 3, md: 3}} sx={{width: '100%'}}>
              <Divider />
            </Grid>
            <Grid container columnSpacing={1} order={{xs: 2, md: 3}} sx={styleSocialIconsWrapper}>
              {routes.map(route => (
                <Grid key={`footer-${route.key}`}>
                  <IconButton color="inherit" size="large" edge="end" href={route.url} aria-label={route.name}>
                    {route.icon}
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
};

export default Footer;
