import contained from './contained';
import gradient from './gradient';
import outlined from './outlined';
import root from './root';
import text from './text';

const button = {
  defaultProps: {
    disableRipple: false,
  },
  styleOverrides: {
    root: {...root},
    contained: {...contained.base},
    containedLight: {...contained.containedLight},
    containedSizeLarge: {...contained.large},
    containedSizeSmall: {...contained.small},
    gradient: {...gradient.base},
    gradientSizeLarge: {...gradient.large},
    gradientSizeSmall: {...gradient.small},
    outlined: {...outlined.base},
    outlinedSizeLarge: {...outlined.large},
    outlinedSizeSmall: {...outlined.small},
    text: {...text.base},
    textSizeLarge: {...text.large},
    textSizeSmall: {...text.small},
  },
};

export default button;
