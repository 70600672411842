import {useLocalStorage} from 'usehooks-ts';

const THEME_DEFAULT = 'Mui';

interface UseThemeOutput {
  themeName: string;
  enable: (newTheme: string) => void;
  disable: () => void;
}

const useTheme = (defaultValue?: string): UseThemeOutput => {
  const [themeName, setTheme] = useLocalStorage<string>('kog-theme', defaultValue ?? THEME_DEFAULT);

  return {
    themeName,
    enable: (newTheme: string) => setTheme(newTheme ?? THEME_DEFAULT),
    disable: () => setTheme(THEME_DEFAULT),
  };
};

export default useTheme;
