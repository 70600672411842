import {ThemeOptions} from '@mui/material/styles';

const typography = (theme: ThemeOptions) => ({
  fontFamily: ["'Montserrat'", 'sans-serif'].join(','),
  fontWeightBold: 700,
  fontWeightLight: 300,
  fontWeightMedium: 600,
  fontWeightRegular: 400,

  body1: {
    fontWeight: 500,
  },

  h1: {
    fontSize: 60,
    fontWeight: 700,
    lineHeight: 1.167,

    // @ts-ignore
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  },

  h2: {
    fontSize: 50,
    fontWeight: 700,
    lineHeight: 1.2,

    // @ts-ignore
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  },

  h3: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: 1.19,

    // @ts-ignore
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },

  h4: {
    fontSize: 21,
    fontWeight: 400,
    lineHeight: 1.19,
  },

  h5: {
    fontSize: 16,
    fontWeight: 600,
    // lineHeight: 1.19,

    letterSpacing: '0.8px',
    textTransform: 'uppercase',
  },

  h6: {
    fontSize: 30,
    fontWeight: 600,
    // lineHeight: 1.19,

    // letterSpacing: '0.8px',
    // textTransform: 'uppercase',
  },
});

export default typography;
