const pagination = {
  styleOverrides: {
    root: {},
    dot: {
      width: 15,
      height: 15,
      backgroundColor: 'transparent',
      border: '1px solid #2C2D30',
      margin: '0 6px',
    },
    dotActive: {
      backgroundColor: '#2C2D30',
    },
    active: {},
    completed: {},
  },
};

export default pagination;
