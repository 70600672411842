import button from './button';
import input from './input';
import list from './list';
import pagination from './pagination';
import toolbar from './toolbar';

const components: object = {
  MuiButton: {...button},
  MuiMobileStepper: {...pagination},
  MuiListItemText: {...list},
  MuiOutlinedInput: {...input},
  MuiToolbar: {...toolbar},
};

export default components;
