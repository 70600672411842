import {useTheme} from '@mui/material/styles';

import {first, get, last, split} from 'lodash';

// todo :: get color type from MUI and replace any
const useColor = (color: string): {color: string; contrast: string} => {
  const theme = useTheme();
  const {palette} = theme;

  const hasColorVariation = color.includes('.');
  const colorArray = (hasColorVariation && split(color, '.')) || [color];
  const paletteColor = first(colorArray);
  const paletteColorVariationFirst = Object.keys(palette[paletteColor as keyof typeof palette])[0];
  const paletteColorVariation = hasColorVariation ? last(colorArray) : paletteColorVariationFirst;
  const paletteColorWithVariation = `${paletteColor}.${paletteColorVariation}`;
  const paletteColorHex = get(palette, paletteColorWithVariation);
  const paletteColorHexContrast = get(palette, `${paletteColor}.contrastText`) || get(palette, 'common.black');

  return {color: paletteColorHex, contrast: paletteColorHexContrast};
};

export default useColor;
