import React from 'react';

import {GoogleAnalytics} from 'nextjs-google-analytics';

import {CssBaseline, ThemeProvider} from '@mui/material';

import {useDarkMode} from 'usehooks-ts';

import useTheme from '@/utilities/useTheme';
import {themes} from '@/themes';

export interface LayoutProps {
  children?: React.ReactNode;
}

const GlobalLayout = ({children = null}: LayoutProps) => {
  const {isDarkMode} = useDarkMode();
  const {themeName} = useTheme('Jkgl');

  const theme = themes[themeName as keyof typeof themes];

  return (
    <ThemeProvider theme={isDarkMode ? theme.dark : theme.light}>
      <GoogleAnalytics trackPageViews />
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default GlobalLayout;
