import React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ListItem as MuiListItem} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import ListItem from '@/components/List/ListItem';
import {RouteInterface} from '@/routes';

const styleIcon = {
  fontSize: '2rem',
};

export interface ListCollapseProps {
  openItem: string;
  route: RouteInterface;
  handleClick?: () => void;
}

const ListCollapse = ({route, openItem, handleClick}: ListCollapseProps): JSX.Element => (
  <>
    <MuiListItem disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={route.name} />
        {openItem === route.key ? <ExpandLessIcon sx={styleIcon} /> : <ExpandMoreIcon sx={styleIcon} />}
      </ListItemButton>
    </MuiListItem>

    <Collapse in={openItem === route.key} timeout="auto" unmountOnExit>
      <List component="div" disablePadding sx={{pl: 5, py: 1}}>
        {route.collapse &&
          route.collapse.map(subroute =>
            subroute.collapse ? (
              <ListCollapse key={`MobileNavigation-${route.key}-${subroute.key}`} route={subroute} openItem={openItem} handleClick={handleClick} />
            ) : (
              <ListItem key={`MobileNavigation-${route.key}-${subroute.key}`} route={subroute} sx={{padding: 0, margin: 0}} />
            )
          )}
      </List>
    </Collapse>
  </>
);

export default ListCollapse;
