import React from 'react';

import Link from 'next/link';

import type {SxProps} from '@mui/material';
import {Drawer as MuiDrawer} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import PropertyCategory from '@/components/Property/PropertyCategory';
import {RouteInterface} from '@/routes';

const maxWidthDefault = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);
const headerHeightDefault = Number(process.env.NEXT_PUBLIC_FRONTEND_HEADER_HEIGHT);

const styleDrawer = {
  '& .MuiDrawer-paper': {
    top: `${headerHeightDefault}px`,
    overflow: 'hidden',
  },
};

const styleLink = {
  textDecoration: 'none',
};

export interface DrawerProps {
  open: boolean;
  routes: Array<RouteInterface>;
  maxWidth?: number;
  title: string;
  onClose?: () => void;
  sx?: SxProps;
}

const Drawer = ({open = false, title = '', routes = [], maxWidth = maxWidthDefault, onClose, sx}: DrawerProps): JSX.Element => (
  <MuiDrawer
    anchor="top"
    open={open}
    onClose={onClose}
    ModalProps={{keepMounted: true}} // Better open performance on mobile.
    sx={{...styleDrawer, ...sx}}>
    <Box onClick={onClose}>
      <Grid container justifyContent="center">
        <Grid container spacing={3} pt={5} sx={{width: '100%', maxWidth: `${maxWidth}px`}}>
          <Grid xs={12} md={4}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid container xs={12} md={8}>
            {routes.map(route => (
              <Grid key={`NavigationDesktop-${route.key}`} xs={6} md={3}>
                <Link href={`${route.url}`} style={styleLink}>
                  <PropertyCategory description={route.name} image={route.image || ''} aspectRatio="6 / 4" />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </MuiDrawer>
);

export default Drawer;
