const input = {
  styleOverrides: {
    notchedOutline: {
      border: '1px solid #707070',
      borderRadius: 10,
    },
  },
};

export default input;
