const list = {
  styleOverrides: {
    primary: {
      color: '#2C2D30',
      fontSize: '25px',
      fontWeight: '600',
      textTransform: 'capitalize',
    },
  },
};

export default list;
