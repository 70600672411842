import React, {useCallback, useState} from 'react';

import Image from 'next/image';
import Link from 'next/link';

import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {SxProps, ThemeOptions} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Unstable_Grid2';

import {routesHeader as routes} from '@/routes';

import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';

const HEIGHT = Number(process.env.NEXT_PUBLIC_FRONTEND_HEADER_HEIGHT || 99);
const MAX_WIDTH = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

const styleNavigation = {
  zIndex: 9999,

  '@media print': {
    boxShadow: 'none',
  },
};

const styleLink = {
  textDecoration: 'none',
};

const styleButton: SxProps = (theme: ThemeOptions) => ({
  letterSpacing: 0,
  color: 'text.primary',
  textTransform: 'capitalize',
  fontWeight: 600,
  fontSize: '1rem',
  height: '42px',

  // @ts-ignore
  [theme.breakpoints.only('lg')]: {
    fontSize: '0.9rem',
  },
});

const styleMobileMenuIcon = {
  margin: 0,
};

const styleDesktopMenuIcon = {
  fontSize: '2rem !important',
};

const styleDesktop = {
  display: {
    xs: 'none',
    lg: 'inherit',
  },

  '@media print': {
    display: 'none',
  },
};

const styleMobile = {
  display: {
    xs: 'inherit',
    lg: 'none',
  },

  '@media print': {
    display: 'none',
  },
};

const Header = () => {
  const theme = useTheme();

  const [mobileNavigationOpen, setNavigationMobileOpen] = useState(false);
  const [desktopNavigationOpen, setDesktopNavigationOpen] = useState('');

  const handleMobileDrawerToggle = useCallback(() => {
    setNavigationMobileOpen(prevState => !prevState);
  }, []);

  const handleDesktopDrawerToggle = useCallback(
    (type: string) => {
      setDesktopNavigationOpen(type === desktopNavigationOpen ? '' : type);
    },
    [desktopNavigationOpen]
  );

  return (
    <AppBar component="nav" color="inherit" sx={styleNavigation}>
      <Toolbar sx={{maxWidth: `${MAX_WIDTH}px`, mx: 'auto', width: '100%', justifyContent: 'space-between', height: HEIGHT, alignItems: 'center'}}>
        <Link href="/" style={{height: 'auto', flexGrow: 1}}>
          <Image src="/logos/logo-jkgl.png" height="69" width="206" alt={`${process.env.NEXT_PUBLIC_COMPANY_NAME}`} />
        </Link>
        {routes.map(route => (
          <Box key={route.key} sx={styleDesktop}>
            {!route.collapse && (
              <Link href={`${route.url}`} style={styleLink}>
                <Button variant="text" color="primary" sx={styleButton(theme)} onClick={() => handleDesktopDrawerToggle(route.key)}>
                  {route.name}
                </Button>
              </Link>
            )}

            {route.collapse && (
              <Button variant="text" color="primary" sx={styleButton(theme)} onClick={() => handleDesktopDrawerToggle(route.key)}>
                {route.name}
                {route.key === desktopNavigationOpen && <ExpandLessIcon sx={styleDesktopMenuIcon} />}
                {route.key !== desktopNavigationOpen && <ExpandMoreIcon sx={styleDesktopMenuIcon} />}
              </Button>
            )}

            {route.collapse && (
              <Box component="nav">
                <NavigationDesktop
                  open={desktopNavigationOpen === route.key}
                  title={route.name}
                  routes={route.collapse}
                  onClose={() => handleDesktopDrawerToggle('')}
                />
              </Box>
            )}
          </Box>
        ))}

        <Grid sx={styleMobile}>
          <IconButton color="inherit" edge="end" sx={styleMobileMenuIcon} onClick={handleMobileDrawerToggle} aria-label="open navigation">
            {mobileNavigationOpen && <CloseIcon />}
            {!mobileNavigationOpen && <MenuIcon />}
          </IconButton>
        </Grid>
      </Toolbar>
      <Box component="nav">
        <NavigationMobile open={mobileNavigationOpen} routes={routes} onClose={handleMobileDrawerToggle} />
      </Box>
    </AppBar>
  );
};

export default Header;
