const linearGradient = (colors: string[], angle: number = 195): string => {
  const colorGradient = colors.map((val, index) => {
    const percentage = Math.floor((100 / (colors.length - 1)) * index);
    return `${val} ${percentage}%`;
  });

  return `linear-gradient(${angle}deg, ${colorGradient.join(', ')})`;
};

export default linearGradient;
