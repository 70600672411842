import routesHeader from './Header';
import routesSocial from './Socials';

export interface RouteInterface {
  key: string;
  name: string;
  type: string;
  image?: string;
  url: string;
  icon?: JSX.Element;
  route?: string;
  collapse?: Array<RouteInterface>;
}

export {routesHeader, routesSocial};
export default routesHeader;
