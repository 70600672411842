const toolbar = {
  styleOverrides: {
    root: ({theme}: {theme: any}) => ({
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }),
  },
};

export default toolbar;
