import React from 'react';

import Image from 'next/image';

import type {SxProps} from '@mui/material';
import Box from '@mui/material/Box';

const styleBackgroundImageWrapper = (aspectRatio = '1 / 1', maxHeight = 'inherit', background = false) => ({
  aspectRatio,
  minHeight: 'inherit',
  minWidth: '100%',
  position: background ? 'absolute' : 'relative',
  width: '100%',
  maxHeight,
  top: 0,
});

const styleBackgroundImage = (background = false) => ({
  objectFit: 'cover',
  zIndex: background ? -10 : 'auto',
});

const styleOverlay = (background = false) => ({
  background: 'transparent linear-gradient(180deg, #677F88 0%, #7E929AD8 30%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
  bottom: 0,
  color: '#fff',
  left: 0,
  mixBlendMode: 'multiply',
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  top: 0,
  transform: 'matrix(-1, 0, 0, -1, 0, 0)',
  width: '100%',
  zIndex: background ? -5 : 'auto',
});

export interface ImageWrapperProps {
  image?: string;
  title?: string;
  aspectRatio?: string;
  maxHeight?: string;
  background?: boolean;
  overlay?: boolean;
  priority?: boolean;
  sx?: SxProps;
}

const ImageRatio = ({
  image = '',
  title = '',
  aspectRatio = '1 / 1',
  maxHeight = 'inherit',
  background = false,
  overlay = false,
  priority = false,
  sx = {},
}: ImageWrapperProps): JSX.Element => {
  const imageElement = (
    <Image
      src={image}
      alt={title}
      fill
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
      quality={100}
      priority={priority}
      // @ts-ignore
      style={{...styleBackgroundImage(background), ...sx}}
    />
  );

  return (
    <Box sx={styleBackgroundImageWrapper(aspectRatio, maxHeight, background)}>
      {overlay && <Box sx={{...styleOverlay(background)}} />}
      {imageElement}
    </Box>
  );
};

export default ImageRatio;
