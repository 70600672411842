import merge from 'lodash/merge';

import Jkgl from './Jkgl';
import MaterialDashboard from './MaterialDashboard';
import Mui from './Mui';

const overrideDevelopment =
  process.env.NEXT_PUBLIC_IS_DEVELOPEMENT === 'true'
    ? {
        components: {
          MuiGrid2: {
            styleOverrides: {
              root: {
                // border: '1px solid #bbb !important',
              },
            },
          },
        },
      }
    : {};

const themes = {
  Mui: {
    ...Mui,
    light: merge(Mui.light, overrideDevelopment),
    dark: merge(Mui.dark, overrideDevelopment),
  },
  MaterialDashboard: {
    ...MaterialDashboard,
    light: merge(MaterialDashboard.light, overrideDevelopment),
    dark: merge(MaterialDashboard.dark, overrideDevelopment),
  },
  Jkgl: {
    ...Jkgl,
    light: merge(Jkgl.light, overrideDevelopment),
    dark: merge(Jkgl.light, overrideDevelopment), // there is no dark theme
  },
};

export {themes};

export default themes;
