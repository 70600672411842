import {event} from 'nextjs-google-analytics';

const handleEventWithConversion = (action: string, category = 'Visitor') => {
  event(action, {category});
  event('conversion', {
    send_to: 'AW-833782124/7QWNCPqW0-IBEOyCyo0D',
    event_callback: () => {
      // eslint-disable-next-line no-console
      console.log('tracked');
    },
  });
};

export default handleEventWithConversion;
