import React, {useState} from 'react';

import type {SxProps} from '@mui/material';
import {Drawer as MuiDrawer} from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import ImageRatio from '@/components/Image/ImageRatio';
import {ListCollapse, ListItem} from '@/components/List';
import {RouteInterface} from '@/routes';

const headerHeightDefault = Number(process.env.NEXT_PUBLIC_FRONTEND_HEADER_HEIGHT);

const styleDrawer = {
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '600px',
    top: `${headerHeightDefault}px`,
    boxShadow: 'none',
    height: 'fill-available',
  },
};
const styleImageWrapper = {
  maxWidth: '600px',
  margin: '0 auto',
  marginTop: 2,
  marginBottom: 2,
};
const styleImage = {
  borderRadius: '20px',
};

export interface DrawerProps {
  open: boolean;
  routes: Array<RouteInterface>;
  onClose?: () => void;
  sx?: SxProps;
}

const Drawer = ({open = false, routes = [], onClose, sx}: DrawerProps): JSX.Element => {
  const [openItem, setOpenItem] = useState('');

  const handleClick = (key: string) => {
    setOpenItem(key === openItem ? '' : key);
  };

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{keepMounted: true}} // Better open performance on mobile.
      sx={{...styleDrawer, ...sx}}>
      <Box pt={2} pl={3} pr={3}>
        <List>
          {routes.map(route =>
            route.collapse ? (
              <ListCollapse key={`MobileNavigation-${route.key}`} route={route} openItem={openItem} handleClick={() => handleClick(route.key)} />
            ) : (
              <ListItem key={`MobileNavigation-${route.key}`} route={route} />
            )
          )}
        </List>
        <Box sx={styleImageWrapper}>
          <ImageRatio image="/images/warehouse@2x.png" title="warehouse" aspectRatio="6 / 5" sx={styleImage} />
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
