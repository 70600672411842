import React from 'react';
import PropTypes from 'prop-types';

// Components
import Box from '@mui/material/Box';

// Styles
const styleWrapper = {height: '100vh', display: 'flex', flexDirection: 'column'};
const styleHeader = {alignItems: 'center', paddingRight: '40px', display: 'inline-flex', width: '100%'};
const styleNavigationContainer = {height: '100%'};
const styleFooter = {minHeight: '90px', flexShrink: 0};
const styleBodyContainer = {display: 'flex', flex: '1 0 auto'};
const styleBody = {display: 'flex', flex: '1 1 0%', flexDirection: 'column', overflow: 'hidden'};

const DashboardLayout = ({children, header, footer, navigation, sxChildren, sxHeader, sxFooter, sxNavigation, ...rest}) => (
  <Box sx={styleWrapper} {...rest}>
    <Box style={styleBodyContainer}>
      <Box xs={12} sx={{...styleNavigationContainer, ...sxNavigation}}>
        {navigation}
      </Box>
      <Box style={styleBody}>
        <Box p={1} style={{...styleHeader, ...sxHeader}}>
          {header}
        </Box>
        <Box p={1} sx={{}}>
          {children}
        </Box>
      </Box>
    </Box>
    {footer && (
      <Box xs={12} sx={{...styleFooter, ...sxFooter}}>
        {footer}
      </Box>
    )}
  </Box>
);

DashboardLayout.defaultProps = {
  children: null,
  header: null,
  footer: null,
  navigation: null,
  sxChildren: {border: '1px solid red'},
  sxFooter: {border: '1px solid red'},
  sxHeader: {border: '1px solid red'},
  sxNavigation: {border: '1px solid red'},
};

DashboardLayout.propTypes = {
  children: PropTypes.element,
  header: PropTypes.element,
  footer: PropTypes.element,
  navigation: PropTypes.element,
  sxChildren: PropTypes.shape({}),
  sxFooter: PropTypes.shape({}),
  sxHeader: PropTypes.shape({}),
  sxNavigation: PropTypes.shape({}),
};

DashboardLayout.displayName = 'DashboardLayout';
export default DashboardLayout;
