import {PaletteMode} from '@mui/material';

export const palette = {
  mode: 'dark' as PaletteMode,

  gradients: {
    inherit: {
      main: '#e0e0e0',
      dark: '#e0e0e0',
    },

    primary: {
      main: '#90caf9',
      dark: '#42a5f5',
    },

    secondary: {
      main: '#ce93d8',
      dark: '#ab47bc',
    },

    info: {
      main: '#29b6f6',
      dark: '#0288d1',
    },

    success: {
      main: '#66bb6a',
      dark: '#388e3c',
    },

    warning: {
      main: '#ffa726',
      dark: '#f57c00',
    },

    error: {
      main: '#f44336',
      dark: '#d32f2f',
    },
  },

  socialMediaColors: {
    facebook: {
      main: '#3b5998',
      dark: '#344e86',
    },

    twitter: {
      main: '#55acee',
      dark: '#3ea1ec',
    },

    instagram: {
      main: '#125688',
      dark: '#0e456d',
    },

    linkedin: {
      main: '#0077b5',
      dark: '#00669c',
    },

    pinterest: {
      main: '#cc2127',
      dark: '#b21d22',
    },

    youtube: {
      main: '#e52d27',
      dark: '#d41f1a',
    },

    vimeo: {
      main: '#1ab7ea',
      dark: '#13a3d2',
    },

    slack: {
      main: '#3aaf85',
      dark: '#329874',
    },

    dribbble: {
      main: '#ea4c89',
      dark: '#e73177',
    },

    github: {
      main: '#24292e',
      dark: '#171a1d',
    },

    reddit: {
      main: '#ff4500',
      dark: '#e03d00',
    },

    tumblr: {
      main: '#35465c',
      dark: '#2a3749',
    },
  },
};

export default palette;
