import React from 'react';

import {Divider as MuiDivider} from '@mui/material';

const styleDivider = {
  borderWidth: '1px',
  borderColor: 'rgba(0, 0, 0, 0.2)',
};

export interface DividerProps {
  sx?: object;
}

const Divider = ({sx = {}}: DividerProps): JSX.Element => <MuiDivider sx={{...styleDivider, ...sx}} />;

export default Divider;
