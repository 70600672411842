const routesHeader = [
  {
    type: 'collapse',
    name: 'Properties For Lease',
    key: 'properties-for-lease',
    url: '/auckland-property-vacancies-and-rentals/all',
    collapse: [
      {
        type: 'collapse',
        name: 'Warehouse',
        key: 'lease-warehouse',
        url: '/auckland-property-vacancies-and-rentals/warehouses',
        image: '/images/warehouse@2x.png',
      },
      {
        type: 'collapse',
        name: 'Office',
        key: 'lease-office',
        url: '/auckland-property-vacancies-and-rentals/offices',
        image: '/images/office@2x.png',
      },
      {
        type: 'collapse',
        name: 'Retail',
        key: 'lease-retail',
        url: '/auckland-property-vacancies-and-rentals/retail-show-rooms',
        image: '/images/retail@2x.png',
      },
      {
        type: 'collapse',
        name: 'Residential',
        key: 'lease-residential',
        url: '/auckland-property-vacancies-and-rentals/residential',
        image: '/images/residential@2x.png',
      },
      {
        type: 'collapse',
        name: 'Land Yards',
        key: 'sale-land-yards',
        url: '/auckland-land-yards-for-lease-and-sale',
        image: '/images/land-yards@2x.png',
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Properties For Sale',
    key: 'properties-for-sale',
    url: '/auckland-properties-for-sale',
    // collapse: [
    //   {
    //     type: 'collapse',
    //     name: 'Warehouse',
    //     key: 'sale-warehouse',
    //     url: '/auckland-properties-for-sale/warehouses',
    //     image: '/images/warehouse@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Office',
    //     key: 'sale-office',
    //     url: '/auckland-properties-for-sale/offices',
    //     image: '/images/office@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Retail',
    //     key: 'sale-retail',
    //     url: '/auckland-properties-for-sale/retail-show-rooms',
    //     image: '/images/retail@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Development',
    //     key: 'sale-development',
    //     url: '/auckland-properties-for-sale/new-developments',
    //     image: '/images/developments@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Residential',
    //     key: 'sale-residential',
    //     url: '/auckland-properties-for-sale/residential',
    //     image: '/images/residential@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Properties for Sale',
    //     key: 'uckland-properties-for-sale',
    //     url: '/auckland-properties-for-sale/auckland-properties-for-sale',
    //     image: '/images/properties-for-sale@2x.png',
    //   },
    //   {
    //     type: 'collapse',
    //     name: 'Land Yards',
    //     key: 'sale-land-yards',
    //     url: '/auckland-land-yards-for-lease-and-sale',
    //     image: '/images/land-yards@2x.png',
    //   },
    // ],
  },
  {
    type: 'collapse',
    name: 'Development',
    key: 'development',
    url: '/auckland-properties-for-sale/new-developments',
  },
  {
    type: 'collapse',
    name: 'Sustainability',
    key: 'sustainability',
    url: '/sustainability',
  },
  {
    type: 'collapse',
    name: 'About Us',
    key: 'about-us',
    url: '/about-us-and-auckland-commercial-office-space',
  },
];

export default routesHeader;
