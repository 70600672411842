import {pxToRem} from '@/utilities';

import typography from '../../typography';

// @ts-ignore
const {fontWeightBold} = typography;

const root = {
  alignItems: 'center',
  backgroundPositionX: '25%',
  backgroundSize: '150%',
  display: 'inline-flex',
  fontSize: pxToRem(12),
  fontWeight: fontWeightBold,
  justifyContent: 'center',
  lineHeight: 1.4,
  minHeight: pxToRem(40),
  padding: `${pxToRem(10)} ${pxToRem(24)}`,
  textAlign: 'center',
  textTransform: 'uppercase',
  transition: 'all 150ms ease-in',
  userSelect: 'none',

  '& .material-icon, .material-icons-round, svg': {
    fontSize: `${pxToRem(16)} `,
  },
};

export default root;
