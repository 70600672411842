import {pxToRem} from '@/utilities';

const buttonText = {
  base: {},

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: pxToRem(12),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)}`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: pxToRem(14),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)}`,
    },
  },
};

export default buttonText;
