import {pxToRem} from '@/utilities';

const contained = {
  base: {},

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: pxToRem(12),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)}`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)}`,
    },
  },

  containedLight: {
    color: '#2C2D30',
    textWeight: 600,
    letterSpacing: '0.8px',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
  },
};

export default contained;
