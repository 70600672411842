import {PaletteMode} from '@mui/material';

export const palette = {
  mode: 'light' as PaletteMode,

  primary: {
    main: '#e91e63',
  },
  secondary: {
    main: '#7b809a',
  },
  info: {
    main: '#1A73E8',
  },
  success: {
    main: '#4CAF50',
  },
  warning: {
    main: '#fb8c00',
  },
  error: {
    main: '#F44335',
  },
  background: {
    default: '#f0f2f5',
  },
  text: {
    primary: '#344767',
  },

  gradients: {
    inherit: {
      main: '#e0e0e0',
      dark: '#e0e0e0',
    },

    primary: {
      main: '#EC407A',
      dark: '#D81B60',
    },

    secondary: {
      main: '#747b8a',
      dark: '#495361',
    },

    info: {
      main: '#49a3f1',
      dark: '#1A73E8',
    },

    success: {
      main: '#66BB6A',
      dark: '#43A047',
    },

    warning: {
      main: '#FFA726',
      dark: '#FB8C00',
    },

    error: {
      main: '#EF5350',
      dark: '#E53935',
    },
  },

  socialMediaColors: {
    facebook: {
      main: '#3b5998',
      dark: '#344e86',
    },

    twitter: {
      main: '#55acee',
      dark: '#3ea1ec',
    },

    instagram: {
      main: '#125688',
      dark: '#0e456d',
    },

    linkedin: {
      main: '#0077b5',
      dark: '#00669c',
    },

    pinterest: {
      main: '#cc2127',
      dark: '#b21d22',
    },

    youtube: {
      main: '#e52d27',
      dark: '#d41f1a',
    },

    vimeo: {
      main: '#1ab7ea',
      dark: '#13a3d2',
    },

    slack: {
      main: '#3aaf85',
      dark: '#329874',
    },

    dribbble: {
      main: '#ea4c89',
      dark: '#e73177',
    },

    github: {
      main: '#24292e',
      dark: '#171a1d',
    },

    reddit: {
      main: '#ff4500',
      dark: '#e03d00',
    },

    tumblr: {
      main: '#35465c',
      dark: '#2a3749',
    },
  },

  // badgeColors: {
  //   primary: {
  //     background: '#f8b3ca',
  //     text: '#cc084b',
  //   },

  //   secondary: {
  //     background: '#d7d9e1',
  //     text: '#6c757d',
  //   },

  //   info: {
  //     background: '#aecef7',
  //     text: '#095bc6',
  //   },

  //   success: {
  //     background: '#bce2be',
  //     text: '#339537',
  //   },

  //   warning: {
  //     background: '#ffd59f',
  //     text: '#c87000',
  //   },

  //   error: {
  //     background: '#fcd3d0',
  //     text: '#f61200',
  //   },

  //   light: {
  //     background: '#ffffff',
  //     text: '#c7d3de',
  //   },

  //   dark: {
  //     background: '#8097bf',
  //     text: '#1e2e4a',
  //   },
  // },

  // coloredShadows: {
  //   primary: '#e91e62',
  //   secondary: '#110e0e',
  //   info: '#00bbd4',
  //   success: '#4caf4f',
  //   warning: '#ff9900',
  //   error: '#f44336',
  //   light: '#adb5bd',
  //   dark: '#404040',
  // },
};

export default palette;
