import React from 'react';

import type {AppProps} from 'next/app';

import {Box} from '@mui/material';

import {Footer} from '@/components/Footer';
import {Header} from '@/components/Header';
import {GlobalLayout} from '@/components/Layout';

import '../styles/globals.css';

const HEIGHT = Number(process.env.NEXT_PUBLIC_FRONTEND_HEADER_HEIGHT || 99);

const Jkgl = ({Component, pageProps}: AppProps) => (
  <GlobalLayout>
    {/* Header is fixed to the top of the screen */}
    <Header />
    <Box display="grid" gridTemplateRows="1fr auto" minHeight="100dvh" pt={`${HEIGHT}px`}>
      <Component {...pageProps} />
      <Footer />
    </Box>
  </GlobalLayout>
);

export default Jkgl;
