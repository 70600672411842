import React from 'react';

import Link from 'next/link';

import {ListItem as MuiListItem} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import {RouteInterface} from '@/routes';

const styleLink = {
  textDecoration: 'none',
};

export interface ListItemElementProps {
  route: RouteInterface;
  sx?: object;
}

const ListItem = ({route, sx = {}}: ListItemElementProps): JSX.Element => (
  <Link key={`ListItemElement-${route.key}`} href={route.url} style={styleLink}>
    <MuiListItem disablePadding>
      <ListItemButton sx={sx}>
        <ListItemText primary={route.name} />
      </ListItemButton>
    </MuiListItem>
  </Link>
);

export default ListItem;
