import isEmpty from 'lodash/isEmpty';

export type CoordinatesInterface = {
  latitude: number;
  longitude: number;
  distance?: number;
};

// Converts numeric degrees to radians
const toRad = (Value: number) => (Value * Math.PI) / 180;

// This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
const calcCrowFliesInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // km

  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d || 0;
};

const getCoordinatesCenter = (locations: Array<CoordinatesInterface> = [], defaultLatitude: number = 0, defaultLongitude: number = 0): CoordinatesInterface => {
  const coordinates = isEmpty(locations) ? [{latitude: defaultLatitude, longitude: defaultLongitude}] : locations;

  const latitudes = coordinates.map(location => location.latitude);
  const longitudes = coordinates.map(location => location.longitude);

  const coordsMin = {
    latitudes: Math.min.apply(null, latitudes),
    longitudes: Math.min.apply(null, longitudes),
  };
  const coordsMax = {
    latitudes: Math.max.apply(null, latitudes),
    longitudes: Math.max.apply(null, longitudes),
  };

  const distance = calcCrowFliesInKm(coordsMin.latitudes, coordsMin.longitudes, coordsMax.latitudes, coordsMax.longitudes);

  const result = coordinates.reduce(
    (previous, location) => ({
      latitude: previous.latitude + Number(location.latitude),
      longitude: previous.longitude + Number(location.longitude),
    }),
    {
      latitude: 0,
      longitude: 0,
      distance: 0,
    }
  );

  result.latitude /= coordinates.length;
  result.longitude /= coordinates.length;
  result.distance = distance;

  return result;
};

export default getCoordinatesCenter;
