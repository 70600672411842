import {createTheme, ThemeOptions} from '@mui/material/styles';

import components from './components';
import shape from './shape';
import typography from './typography';

const theme: ThemeOptions = createTheme();

const overrides = {
  components: {...theme.components, ...components},
  shape: {...theme.shape, ...shape},
  typography: {...theme.typography, ...typography},
};

export default overrides;
