import React from 'react';

// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export interface RoutesSocialProps {
  type: string;
  name: string;
  key: string;
  icon: JSX.Element;
  url: string;
}

const routesSocial = [
  {
    type: 'collapse',
    name: 'LinkedIn',
    key: 'socials-linkedin',
    icon: <LinkedInIcon />,
    url: `${process.env.NEXT_PUBLIC_COMPANY_LINKEDIN}`,
  },
  // {
  //   type: 'collapse',
  //   name: 'Facebook',
  //   key: 'socials-facebook',
  //   icon: <FacebookIcon />,
  //   url: `${process.env.NEXT_PUBLIC_COMPANY_FACEBOOK}`,
  // },
  // {
  //   type: 'collapse',
  //   name: 'Instagram',
  //   key: 'socials-instagram',
  //   icon: <InstagramIcon />,
  //   url: `${process.env.NEXT_PUBLIC_COMPANY_INSTAGRAM}`,
  // },
  // {
  //   type: 'collapse',
  //   name: 'Twitter',
  //   key: 'socials-twitter',
  //   icon: <TwitterIcon />,
  //   url: `${process.env.NEXT_PUBLIC_COMPANY_TWITTER}`,
  // },
  // {
  //   type: 'collapse',
  //   name: 'WhatsApp',
  //   key: 'socials-whatsapp',
  //   icon: <WhatsAppIcon />,
  //   url: `${process.env.NEXT_PUBLIC_COMPANY_WHATSUP}`,
  // },
];

export default routesSocial;
