import React from 'react';
import PropTypes from 'prop-types';

// Components
import Box from '@mui/material/Box';

// Styles
const styleWrapper = {
  overflowX: 'hidden',
};
const styleImage = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  zIndex: -1,
};

const BackgroundLayout = ({background, image, children, ...rest}) => (
  <Box width="100vw" height="100%" minHeight="100vh" bgColor={background} sx={{styleWrapper}}>
    <Box
      position="absolute"
      width="100%"
      minHeight="100vh"
      sx={{
        backgroundImage: () => (image ? `url(${image.src || image})` : 'none'),
        ...styleImage,
      }}
      {...rest}
    />
    <Box px={1} width="100%" height="100vh" mx="auto">
      {children}
    </Box>
  </Box>
);

BackgroundLayout.defaultProps = {
  background: 'transparent',
  image: 'https://picsum.photos/1200/800',
};

BackgroundLayout.propTypes = {
  background: PropTypes.oneOf([
    'transparent',
    'white',
    'black',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-400',
    'grey-500',
    'grey-600',
    'grey-700',
    'grey-800',
    'grey-900',
  ]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
};

BackgroundLayout.displayName = 'BackgroundLayout';
export default BackgroundLayout;
