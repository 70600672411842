import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import ImageRatio from '@/components/Image/ImageRatio';

export interface PropertyCategoryProps {
  title?: string;
  image: string;
  description?: string;
  aspectRatio?: string;
}

const stylePaper = {
  border: 'none',
  mb: 2,
};
const styleImage = {
  borderRadius: '20px',
};
const styleDescription = {fontWeight: 'bold'};

const PropertyCategory = ({title = '', image = '', description = '', aspectRatio = '1 / 1'}: PropertyCategoryProps): JSX.Element => (
  <Card variant="outlined" sx={stylePaper}>
    {title && (
      <CardContent sx={{p: 0, mb: 2}}>
        <Grid container spacing={0.5}>
          <Grid xs={12}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    )}
    <CardMedia title={title}>
      <Grid container>
        <Grid xs={12}>
          <ImageRatio image={image} title={title} aspectRatio={aspectRatio} sx={styleImage} />
        </Grid>
      </Grid>
    </CardMedia>
    {description && (
      <CardContent sx={{p: 0, mt: 2}}>
        <Grid container spacing={0.5}>
          <Grid>
            <Typography sx={styleDescription}>{description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    )}
  </Card>
);

export default PropertyCategory;
