import {createTheme} from '@mui/material';

import overrides from '../overrides';
import {palette} from './palette';

export const lightTheme = createTheme({
  ...overrides, // theme overrides used by both light and dark themes
  palette, // custom palette
});

export default lightTheme;
